import React from "react";
import AppContainer from "../components/AppContainer";
import {
    Container,
    Form,
    Button,
    Message} from "semantic-ui-react";
import { Formik, useField } from "formik";
import * as Yup from "yup";

import "./Registration.css";

const states = "|AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY|";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Input
        {...field}
        {...props}
        label={label}
        error={meta.touched && meta.error}
    />
  );
};

const SelectInput = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    const handleChange = (_, { value }) => {
        helpers.setValue(value);
    };

    return (
        <Form.Select
            id={`field_dropdown_${field.name}`}
            label={label}
            {...field}
            {...props}
            selectOnBlur={false}
            error={meta.error}
            onChange={handleChange}
        />
    );
};

const CheckboxInput = ({ label, ...props }) => {
    const [field, meta] = useField({...props, type: "checkbox"});

    return (
        <Form.Checkbox
            id={`field_checkbox${field.name}`}
            label={label}
            {...field}
            {...props}
            error={meta.touched && meta.error}
        />
    );
};

const handleSubmit = async (values, setStatus) => {
    try {
        var res = await fetch(
            process.env.REACT_APP_REGISTER_URL,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow",
                body: JSON.stringify(values)
            });

        if (res.ok) {
            setStatus({ error: false, success: true });
        } else {
            setStatus({ error: true, success: false });
        }
    } catch (error) {
        setStatus({ error: true, success: false })
    }
};

const RegistrationForm = () => {
    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                firstAddressLine: "",
                secondAddressLine: "",
                city: "",
                state: "",
                zip: "",
                cell: "",
                email: "",
                dob: "",
                gender: "",
                mealType: "",
                yearsAtBrc: "",
                school: "",
                grade: "",
                shirtSize: "",
                campType: "",
                parentConsent: false,
                parentFirstName: "",
                parentLastName: "",
                parentCell: "",
                parentEmail: ""
            }}
            validationSchema={
                Yup.object({
                    firstName: Yup.string().required("Required"),
                    lastName: Yup.string().required("Required"),
                    firstAddressLine: Yup.string().required("Required"),
                    secondAddressLine: Yup.string(),
                    city: Yup.string().required("Required"),
                    state: Yup.string()
                            .strict()
                            .uppercase("Uppercase")
                            .length(2)
                            .test("valid-state", "Invalid State", s => states.includes(s))
                            .required("Required"),
                    zip: Yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip").required("Required"),
                    cell: Yup.string()
                            .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Invalid Cell")
                            .required("Required"),
                    email: Yup.string().email("Invalid Email").required("Required"),
                    dob: Yup.string("Invalid Date").matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, "Date Required").required("Required"),
                    gender: Yup.string().oneOf(["M", "F", "O"]),
                    mealType: Yup.string().oneOf(["Regular", "Vegetarian"]),
                    yearsAtBrc: Yup.string().oneOf(["0", "1", "2", "3", "4"]),
                    school: Yup.string().required("Required"),
                    grade: Yup.string().oneOf(["9", "10", "11", "12"]),
                    shirtSize: Yup.string().oneOf(["S", "M", "L"]),
                    campType: Yup.string().oneOf(["Full", "Weekend"]),
                    parentConsent: Yup.boolean()
                        .required("Required")
                        .oneOf([true], "Parent must provide consent"),
                    parentFirstName: Yup.string().required("Required"),
                    parentLastName: Yup.string().required("Required"),
                    parentCell: Yup.string()
                            .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Invalid Cell")
                            .required("Required"),
                    parentEmail: Yup.string().email("Invalid Email").required("Required"),
                })
            }
            initialStatus={{ error: false, success: false }}
            onSubmit={(values, { setStatus }) => handleSubmit(values, setStatus)}
        >
            {(props) => (
                <Form
                    widths="equal"
                    loading={props.isSubmitting}
                    onSubmit={props.handleSubmit}
                    error={props.status.error}
                    success={props.status.success}
                    {...props}
                >
                    <Form.Group >
                        <TextInput
                            width={7}
                            required
                            label="First Name"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                        />
                        <TextInput
                            width={9}
                            required
                            label="Last Name"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                        />
                    </Form.Group>
                    <TextInput
                        required
                        label="Address Line 1"
                        name="firstAddressLine"
                        type="text"
                        placeholder="Address Line 1"
                    />
                    <TextInput
                        label="Address Line 2"
                        name="secondAddressLine"
                        type="text"
                        placeholder="Address Line 2"
                    />

                    <Form.Group>
                        <TextInput
                            width={12}
                            required
                            label="City"
                            name="city"
                            type="text"
                            placeholder="City"
                        />
                        <TextInput
                            width={2}
                            required
                            label="State"
                            name="state"
                            type="text"
                            placeholder="State"
                        />
                        <TextInput
                            width={2}
                            required
                            label="Zip"
                            name="zip"
                            type="text"
                            placeholder="Zip"
                        />
                    </Form.Group>
                    <Form.Group>
                        <TextInput
                            required
                            label="Cell"
                            name="cell"
                            type="text"
                            placeholder="XXX-XXX-XXXX"
                        />
                        <TextInput
                            required
                            label="Camper Email"
                            name="email"
                            type="text"
                            placeholder="camper@example.com"
                        />
                    </Form.Group>
                    <Form.Group>
                        <TextInput
                            required
                            label="Date of Birth"
                            name="dob"
                            type="text"
                            placeholder="MM/DD/YYYY"
                        />
                        <SelectInput
                            name="gender"
                            required
                            label="Gender"
                            placeholder="Female"
                            options={[
                                { key: "m", text: "Male", value: "M", id: 1},
                                { key: "f", text: "Female", value: "F", id: 2},
                                { key: "o", text: "Other", value: "O", id: 3 }]}
                        />
                        <SelectInput
                            name="mealType"
                            required
                            label="Meal Type"
                            placeholder="Regular"
                            options={[
                                { key: "r", text: "Regular", value: "Regular" },
                                { key: "v", text: "Vegetarian", value: "Vegetarian" }]}
                        />
                        <SelectInput
                            name="yearsAtBrc"
                            required
                            label="Years At Briarwood"
                            placeholder="Years At BRC"
                            options={[
                                { key: 0, text: "0", value: "0" },
                                { key: 1, text: "1", value: "1" },
                                { key: 2, text: "2", value: "2" },
                                { key: 3, text: "3", value: "3" },
                                { key: 4, text: "4", value: "4" }]}
                        />
                    </Form.Group>
                    <Form.Group>
                        <TextInput
                            width={8}
                            required
                            label="School"
                            name="school"
                            type="text"
                            placeholder="School"
                        />
                        <SelectInput
                            width={4}
                            name="grade"
                            required
                            label="Grade (2025 - 2026 School Year)"
                            placeholder="Grade"
                            options={[
                                { key: 0, text: "9", value: "9" },
                                { key: 1, text: "10", value: "10" },
                                { key: 2, text: "11", value: "11" },
                                { key: 3, text: "12", value: "12" }]}
                        />
                        <SelectInput
                            width={4}
                            name="shirtSize"
                            required
                            label="T-shirt Size"
                            placeholder="Shirt Size"
                            options={[
                                { key: 0, text: "Small", value: "S" },
                                { key: 1, text: "Medium", value: "M" },
                                { key: 2, text: "Large", value: "L" }]}
                        />
                    </Form.Group>
                    <SelectInput
                        width={4}
                        name="campType"
                        required
                        label="Camp Type"
                        placeholder="Full"
                        options={[
                            { key: 0, text: "Full", value: "Full" },
                            { key: 1, text: "Weekend", value: "Weekend" }
                        ]}
                    />
                    <Form.Field label="Parent Consent" />
                    <CheckboxInput
                        name="parentConsent"
                        label="
                            I hereby give permission for my son/daughter, named
                            above, to participate in Briarwood Running Camp and
                            I am aware of the risks.
                            I understand and give permission for photographs and
                            videos to be taken, to be used by Briarwood Running
                            Camp in brochures as well as in electronic, video,
                            print, display and other materials.
                            I hereby give permission to the resident
                            physician/trainer to provide routine health care, emergency
                            treatment and administer medication when
                            needed to.
                        "
                    >
                    </CheckboxInput>
                    <Form.Group widths="equal">
                        <TextInput
                            width={7}
                            required
                            label="Parent First Name"
                            name="parentFirstName"
                            type="text"
                            placeholder="Parent First Name"
                        />
                        <TextInput
                            width={9}
                            required
                            label="Parent Last Name"
                            name="parentLastName"
                            type="text"
                            placeholder="ParentLastName"
                        />
                    </Form.Group>
                    <Form.Group>
                        <TextInput
                            required
                            label="Parent Cell"
                            name="parentCell"
                            type="text"
                            placeholder="XXX-XXX-XXXX"
                        />
                        <TextInput
                            required
                            label="Parent Email"
                            name="parentEmail"
                            type="text"
                            placeholder="parent@example.com"
                        />
                    </Form.Group>
                    <Button type="submit">Submit</Button>
                    <Button onClick={props.resetForm}>Reset</Button>
                    <Message
                        success
                        header="Registration Successful!"
                        content="You're all signed up.  A registration confirmation email has been sent."
                    />
                    <Message
                        error
                        header="Registration Failed"
                        content="Please send an email to info@briarwoodrunningcamp.com for further assistance."
                    />
                </Form>
            )}
        </Formik>
    );
}

const Registration = () => (
    <AppContainer>
        <Container>
            <h1 as="h1">2025 Online Registration</h1>
            <p>To register online, complete the following form. After submission, a confirmation email will be sent to the camper with a registration identification number. Once Briarwood Running Camp has received and processed your information, you will be contacted about payment options.</p>
            <RegistrationForm></RegistrationForm>
            <h1 as="h1">2025 Mail Registration</h1>
            <p>To register for Briarwood Summer Camp via mail, please complete the form found in our brochure and mail, with check, to the following address:
                <br/><br/>Briarwood Running Camp
                <br/>PO Box 161
                <br/>Flourtown, PA
                <br/>19031
            </p>
            Mail Registration
        </Container>
    </AppContainer>
);

export default Registration;
